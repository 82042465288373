@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

:root {
  /* Colors */

  /* White */
  --white: #fff;

  /* Orange */
  --orange-200: #F5A483;
  --orange-400: #F27846;

  /* Cyan */
  --cyan-100: #B2DEF9;
  --cyan-200: #3BABF1;
  --cyan-300: #1D7AFC;

  /* Blue */
  --blue-100: #036DAF;
  --blue-200: #2b3a65;

  /* Gray */
  --gray-20: #DFDFDF;
  --gray-50: #C1C1C1;
  --gray-100: #535353;
  --gray-200: #464449;

  /* Yellow */
  --yellow-100: #F5CC91;
  --yellow-200: #f1a63b;

  /* Font */
  --font-regular: "Lato", sans-serif;

  /* Linear Gradient */
  --linear-gradient: linear-gradient(180deg, rgba(43,58,101,1) 0%, rgba(43,58,101,1) 35%, rgba(5,14,37,1) 100%);
}

.btn-yellow {
  background-color: var(--yellow-200);
  border: none;
  color: black;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
}

body,
html {
  font-family: var(--font-regular);
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}